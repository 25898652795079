import { useParams } from 'react-router-dom';
import styles from "./AuthenticationModal.module.scss";
import exclamation from "../../assets/exclamation.png";
import niceAlarm from "../../assets/nice_alarm.png"
import axiosClient from "../../libs/axiosClient";

interface AuthenticationModalProps {
  onClose: () => void;
  onAuthSuccess: () => void;
  cartString: string;
  isDirect: string;
}

const AuthenticationModal: React.FC<AuthenticationModalProps> = ({
  onClose,
  onAuthSuccess,
  cartString,
  isDirect,
}) => {
  const params = useParams();
  
  const fetchNiceAuthData = async (
    cartString?: string,
    isDirect?: string
  ) => {
    const isMobile =
      /Instagram|FB_IAB|FB4A|FBAN|FBIOS/i.test(navigator.userAgent) ||
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    try {
      if (isMobile) {
        console.log("pcode: ", Number(params.productId));
        console.log("cart_string: ", cartString);
        console.log("is_direct: ", isDirect);
        
        const response = await axiosClient.get(
          "/data/verification/nice_id_check",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              pcode: isNaN(Number(params.productId)) ? "" : Number(params.productId),
              cart_string: cartString,
              is_direct: isDirect,
            },
          }
        );
        return response.data;
      } else {
        const response = await axiosClient.get(
          "/data/verification/nice_id_check",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              pcode: isNaN(Number(params.productId)) ? "" : Number(params.productId),
              cart_string: cartString,
              is_direct: isDirect,
            },
          }
        );
        return response.data;
      }
    } catch (error) {
      console.error("Failed to fetch NICE auth data:", error);
      alert("본인인증 데이터 불러오기 실패.");
      throw error;
    }
  };

  const handleAuthClick = async () => {
    const isMobile =
      /Instagram|FB_IAB|FB4A|FBAN|FBIOS/i.test(navigator.userAgent) ||
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
      try {
        const niceAuthData = await fetchNiceAuthData(cartString, isDirect);
  
        if (!niceAuthData?.data) {
          console.error("Invalid NICE auth data:", niceAuthData);
          alert("본인인증 데이터가 올바르지 않습니다.");
          return;
        }
  
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
  
        const inputs = [
          { name: "m", value: "service" },
          { name: "token_version_id", value: niceAuthData.data.token_version_id },
          { name: "enc_data", value: niceAuthData.data.enc_data },
          { name: "integrity_value", value: niceAuthData.data.integrity_value },
        ];
  
        inputs.forEach(({ name, value }) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        });
  
        document.body.appendChild(form);
        form.submit();
      } catch (error) {
        console.error("Error during NICE auth process (Instagram):", error);
        alert("본인인증 중 오류가 발생했습니다.");
      }
    } else {
      const popup = window.open(
        "",
        "niceAuthPopup",
        "width=500,height=600,scrollbars=yes,resizable=yes"
      );
  
      if (!popup) {
        alert("팝업이 차단되었습니다.");
        return;
      }
  
      try {
        const niceAuthData = await fetchNiceAuthData(cartString, isDirect);
  
        if (!niceAuthData?.data) {
          console.error("Invalid NICE auth data:", niceAuthData);
          return;
        }
  
        const form = popup.document.createElement("form");
        form.method = "POST";
        form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
  
        const inputs = [
          { name: "m", value: "service" },
          { name: "token_version_id", value: niceAuthData.data.token_version_id },
          { name: "enc_data", value: niceAuthData.data.enc_data },
          { name: "integrity_value", value: niceAuthData.data.integrity_value },
        ];
  
        inputs.forEach(({ name, value }) => {
          const input = popup.document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        });
  
        popup.document.body.appendChild(form);
        form.submit();
  
        window.addEventListener("message", handlePopupMessage, false);
      } catch (error) {
        console.error("Error during NICE auth process (Popup):", error);
        alert("본인인증 중 오류가 발생했습니다.");
        popup.close();
      }
    }
  };

  const handlePopupMessage = (event: MessageEvent) => {
    const { success, message } = event.data;

    if (success === "true") {
      alert("본인인증에 성공했습니다.");
      window.removeEventListener("message", handlePopupMessage);
      onAuthSuccess();
    } else {
      alert(`본인인증 실패: ${message}`);
      window.removeEventListener("message", handlePopupMessage);
      return; 
    }
  };
  
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <button onClick={onClose} className={styles.closeButton}>
          &times;
        </button>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img src={exclamation} alt="Exclamation Icon" className={styles.image} />
          </div>
          <h1>
            비대면진료 시범사업 개정('24.12.2. 시행)으로
            <br /> 진료 시, 본인 인증이 필요해요
          </h1>
          <p>
            인증은 <span>6개월</span>마다 진행되며 <br /> 반드시 본인 명의의 핸드폰으로 인증해 주세요.
          </p>
          <p>
            본인인증이 어려울 시, <br /> 하단의 물음표 버튼을 눌러 고객센터로 문의해주세요.
          </p>
        </div>
        {/* <div className={styles.niceAlarmContainer}>
          <img src={niceAlarm} alt="NICE Alarm" className={styles.niceAlarm} />
        </div> */}
        <button onClick={handleAuthClick} className={styles.authenticateButton}>
          본인 인증하기
        </button>
      </div>
    </div>
  );
};

export default AuthenticationModal;
