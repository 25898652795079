import { useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Header from '../../components/Header/Header';
import axiosClient from '../../libs/axiosClient';
import { IBasketDataType, IBasketProductType } from '../Basket/Basket';
import rightArrow from '../../assets/rightArrow.png';
import styles from './Questionnaire.module.scss';
import AppointmentsCalendar from '../../components/AppointmentsCalendar/AppointmentsCalendar';
import pencil from '../../assets/question/pencil.png';
import mPrev from '../../assets/question/mPrev.png';
import minus from '../../assets/minus.png';
import plus from '../../assets/plus.png';
import closeBtn from '../../assets/closeBtn02.png';
import bottomArrow from '../../assets/countryDrop.png';
import {
  IAllItemsType,
  IOptionType,
  IProductDetailType,
} from '../ProductDetail/ProductDetail';
import { useQuery } from 'react-query';
import { ITokenInfoType } from '../../auth/auth';
import jwt_decode from 'jwt-decode';

// const telRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
const registrationNumberRegex =
  /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-8][0-9]{6}$/;
const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z ]+$/;
export const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
export interface ISavedPatientType {
  caffeine_sensitivity: number;
  daily_coffee_intake: number;
  diet_pills_experience: string;
  diet_pills_level: number;
  diet_pills_side_effects: string | null;
  disease: null | string;
  height: number;
  is_disease: string;
  is_pregnant: string;
  is_taking_medicines: string;
  phone: string;
  taking_medicines: null | string;
  user_name: string;
  weight: number;
  social_number: string;
  question: string;
  is_oversea: string;
  sns_type: string;
  sns_id: string;
}
function Questionnaire() {
  const location = useLocation();
  const isInitial = new URLSearchParams(location.search).get('isInitial');
  const navigate = useNavigate();
  // const { cartId } = useParams();
  const params = useParams();
  const { productId } = useParams();
  const { isDirect } = useParams();
  const [prescriptionProgramList, setPrescriptionProgramList] = useState<any>(
    [],
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderData, setOrderData] = useState<IBasketDataType>();
  const [firstOptionOpen, setFirstOptionOpen] = useState(false);
  const [selectedFirstOption, setSelectedFirstOption] = useState<IOptionType>();
  const [firstOptionList, setFirstOptionList] = useState<IOptionType[]>();
  const [secondOptionList, setSecondOptionList] = useState<IOptionType[]>();
  const [secondOptionOpen, setSecondOptionOpen] = useState(false);
  const [selectedAllItems, setSelectedAllItems] = useState<IAllItemsType[]>([]);
  const [thirdOptionOpen, setThirdOptionOpen] = useState(false);
  const [thirdOptionList, setThirdOptionList] = useState<IOptionType[]>();
  const [selectedThirdOption, setSelectedThirdOption] = useState<IOptionType>();
  const [allItemsPrice, setAllItemsPrice] = useState(0);
  const [allItemsCount, setAllItemsCount] = useState(0);
  const [selectedSecondOption, setSelectedSecondOption] =
    useState<IOptionType>();
  const [isGlobal, setIsGlobal] = useState(false);
  
  let paramsNoArray = false;
  useEffect(() => {
    if (!params.productId) return;
    if (params.productId?.indexOf('[') !== 0) paramsNoArray = true;
  }, []);

  useEffect(() => {
    if (!prescriptionProgramList.includes(Number(productId))) return;
    if (params.productId?.indexOf('[') !== 0) {
      axiosClient
        .get(`/data/product/detail/${productId}/options`)
        .then((res) => setFirstOptionList(res.data.data));
    }
  }, []);

  useEffect(() => {
    // if (!cartId || !isDirect) return;
    // let itemIdList: number[] = JSON.parse(cartId);
    if (params.productId?.indexOf('[') !== 0) return;
    if (!productId || !isDirect) return;
    let itemIdList: number[] = JSON.parse(productId);
    let queryString = `is_direct=${isDirect}`;
    itemIdList.map((id) => {
      queryString += `&cart_ids=${id}`;
    });
    axiosClient.get(`/data/cart?${queryString}`).then((res) => {
      setOrderData(res.data.data);
    });
  }, []);

  function optionSelectCheck() {
    if (selectedAllItems.length == 0) {
      if (!selectedFirstOption) {
        alert('첫 번째 옵션을 선택해주세요.');
        setFirstOptionOpen(true);
      } else if (!selectedSecondOption) {
        alert('두 번째 옵션을 선택해주세요.');
        setSecondOptionOpen(true);
      } else if (!selectedThirdOption) {
        alert('세 번째 옵션을 선택해주세요.');
        setThirdOptionOpen(true);
      }
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (!selectedFirstOption || !detail) return;
    // option_depth가 1보다 크면 2depth의 option 정보 받아옴
    if (detail.option_depth > 1) {
      axiosClient
        .get(
          `/data/product/detail/${detail.product_id}/options?option_id=${selectedFirstOption.po_uid}&depth=2`,
        )
        .then((res) => setSecondOptionList(res.data));
      setSecondOptionOpen(true);
    } else {
      //이미 선택된 옵션 중에 같은 옵션이 포함되어있는지 있으면 해당 idx추출
      let includeIdx = -1;
      selectedAllItems.map((item, idx) => {
        if (item.first?.po_uid == selectedFirstOption.po_uid) includeIdx = idx;
      });
      //-1에서 변경이 없다면 기존 선택된 옵션에 포함되어 있지 않은 옵션이므로 새로 추가
      if (includeIdx == -1)
        setSelectedAllItems([
          ...selectedAllItems,
          {
            first: selectedFirstOption,
            count: 1,
          },
        ]);
      //-1이 아닌 변경된 값이면 이미 해당 옵션이 선택되어 있으므로 해당 옵션 수량 증가
      else
        setSelectedAllItems((prev) => {
          let newList = prev;
          newList[includeIdx].count += 1;
          return [...newList];
        });
      setSelectedFirstOption(undefined);
      setSelectedSecondOption(undefined);
    }
    setFirstOptionOpen(false);
  }, [selectedFirstOption]);
  // 첫 번째 옵션 선택시
  // 두 번째 옵션 선택시
  useEffect(() => {
    if (!selectedSecondOption || !selectedFirstOption || !detail) return;
    if (detail.option_depth > 2) {
      axiosClient
        .get(
          `/data/product/detail/${detail.product_id}/options?option_id=${selectedSecondOption.po_uid}&depth=3`,
        )
        .then((res) => setThirdOptionList(res.data));
      setThirdOptionOpen(true);
    } else {
      let includeIdx = -1;
      selectedAllItems.map((item, idx) => {
        if (
          item.first?.po_uid == selectedFirstOption.po_uid &&
          item.second?.po_uid == selectedSecondOption.po_uid
        )
          includeIdx = idx;
      });
      if (includeIdx == -1)
        setSelectedAllItems([
          ...selectedAllItems,
          {
            first: selectedFirstOption,
            second: selectedSecondOption,
            count: 1,
          },
        ]);
      else
        setSelectedAllItems((prev) => {
          let newList = prev;
          newList[includeIdx].count += 1;
          return [...newList];
        });
      setSelectedFirstOption(undefined);
      setSelectedSecondOption(undefined);
    }
    setSecondOptionOpen(false);
  }, [selectedSecondOption]);

  function handleCountChange(index: number, isUp: boolean) {
    setSelectedAllItems((prev) =>
      prev.map((item, idx) => {
        if (idx == index) {
          if (isUp) item.count = item.count + 1;
          else if (!isUp && item.count > 1) item.count = item.count - 1;
        }
        return item;
      }),
    );
  }
  function handleSelectedItemDelete(index: number) {
    setSelectedAllItems((prev) => prev.filter((item, idx) => idx !== index));
  }
  // 선택한 옵션 총 가격
  useEffect(() => {
    let newPrice = 0;
    let newCount = 0;
    for (let i = 0; i < selectedAllItems.length; i++) {
      let price = 0;
      if ('third' in selectedAllItems[i])
        price += selectedAllItems[i].third?.po_poptionprice || 0;
      else if ('second' in selectedAllItems[i])
        price += selectedAllItems[i].second?.po_poptionprice || 0;
      else if ('first' in selectedAllItems[i])
        price += selectedAllItems[i].first?.po_poptionprice || 0;
      newPrice = newPrice + price * selectedAllItems[i].count;
      newCount = newCount + selectedAllItems[i].count;
    }
    setAllItemsPrice(newPrice);
    setAllItemsCount(newCount);
  }, [selectedAllItems]);

  const [detail, setDetail] = useState<IProductDetailType>();

  useEffect(() => {
    if (!params.productId) return;
  }, []);

  const { data: detailCacheData } = useQuery<IProductDetailType>(
    ['productDetail', params.productId],
    () =>
      axiosClient
        .get(`/data/product/detail/${params?.productId}`)
        .then((res) => {
          let list = JSON.parse(
            sessionStorage.getItem('product_ids') || JSON.stringify([]),
          );
          if (!list.includes(res.data.data.product_id))
            list.push(res.data.data.product_id);
          sessionStorage.setItem('product_ids', JSON.stringify(list));
          return res.data.data;
        }),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!paramsNoArray,
      onError: () => {
        if (!productId) return;
        const isJson = JSON.parse(productId);
        // if (typeof isJson === 'object') console.log('제이슨');
        if (params.productId?.indexOf('[') === 0) return;
        alert('오류가 발생했습니다.');
        navigate('/');
      },
    },
  );

  useEffect(() => {
    if (detailCacheData) setDetail(detailCacheData);
  }, [detailCacheData]);

  function productOptionCal(product: IBasketProductType) {
    let data = { price: 0, count: 0 };
    product.items.map((option) => {
      data.price += option.discount_price * option.count;
      data.count += option.count;
    });
    return data;
  }

  const saveInfo = () => {
    const infomation: any = {};
    infomation.selectedAllItems = selectedAllItems;
    infomation.patientSecurityNumber1 = watch().patientSecurityNumber1;
    infomation.patientSecurityNumber2 = watch().patientSecurityNumber2;
    infomation.reason = watch().reason;
    infomation.isGlobal = isGlobal;
    infomation.snsIdMethod = watch().snsIdMethod;
    if (isGlobal) infomation.deliverySnsId = watch().snsId;
    infomation.privacyCheck = watch().privacyCheck;
    infomation.cautionCheck = watch().cautionCheck;
    sessionStorage.setItem('quesInfo', JSON.stringify(infomation));
  };

  const validateSecurityNum = () => {
    const securityNum = `${watch().patientSecurityNumber1}${
      watch().patientSecurityNumber2
    }`;

    if (securityNum === '0'.repeat(13)) return true;

    if (securityNum.length !== 13 || isNaN(Number(securityNum))) {
      setError(
        'patientSecurityNumber1',
        {
          message: '주민등록번호를 정확하게 입력해주세요.',
        },
        { shouldFocus: true },
      );
      alert('주민등록번호를 정확하게 입력해주세요.(13자리)');
      return false;
    }

    const multiply = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
    let sum = 0;

    for (let i = 0; i < 12; i++) {
      sum += parseInt(securityNum[i]) * multiply[i];
    }

    const remain = sum % 11;
    // 10이 나오면 => 0으로 해야해서
    const checkCode = (11 - remain) % 10;

    const isSecurity = parseInt(securityNum[12]) === checkCode;
    if (!isSecurity) {
      setError(
        'patientSecurityNumber1',
        {
          message: '유효하지 않은 주민등록번호입니다.',
        },
        { shouldFocus: true },
      );
      alert('유효하지 않은 주민등록번호입니다.');
    }
    return isSecurity;
  };
  const onSubmit = async (data: any) => {
    if (!detail && prescriptionProgramList.includes(Number(productId))) return;
    if (prescriptionProgramList.includes(Number(productId))) {
      let check = optionSelectCheck();
      if (!check) return;
    }
    if (isSubmitting) return;
    // if (!validateSecurityNum()) return;

    setIsSubmitting(true);
    let date = '';
    let time = '';
    let formData = new FormData();
    saveInfo();

    if (prescriptionProgramList.includes(Number(productId))) {
      formData.append('product_id', String(detail && detail.product_id));
      for (let i = 0; i < selectedAllItems.length; i++) {
        if (detail && detail.option_depth <= 1) {
          if (!selectedAllItems[i].first?.po_uid)
            formData.append('option_ids', '0');
          else
            formData.append(
              'option_ids',
              String(selectedAllItems[i].first?.po_uid),
            );
          formData.append('counts', String(selectedAllItems[i].count));
        } else if (detail && detail.option_depth == 2) {
          formData.append(
            'option_ids',
            String(selectedAllItems[i].second?.po_uid),
          );
          formData.append('counts', String(selectedAllItems[i].count));
        } else if (detail && detail.option_depth == 3) {
          formData.append(
            'option_ids',
            String(selectedAllItems[i].third?.po_uid),
          );
          formData.append('counts', String(selectedAllItems[i].count));
        }
      }
      formData.append('is_direct', 'Y');
      axiosClient
        .post('/func/cart', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then(async (res) => {
          let idList: number[] = [];
          res.data.data.cart_ids.map(
            (ids: { status: number; cart_id: number }) => {
              idList.push(ids.cart_id);
            },
          );
          let cartId = JSON.stringify(idList);

          if (isInitial) {
            date = `${appointmentDate!.getFullYear()}-${(
              '0' +
              (appointmentDate!.getMonth() + 1)
            ).slice(-2)}-${appointmentDate!.getDate()}`;
            time =
              (appointmentTime.substring(0, 2) == 'pm' &&
              appointmentTime.substring(2, 4) != '12'
                ? Number(appointmentTime.substring(2, 4)) +
                  12 +
                  ':' +
                  appointmentTime.substring(5, 7)
                : appointmentTime.slice(-5)) + ':00';
            formData.append('is_reservation', 'Y');
            formData.append('reservation_at', `${date} ${time}`);
          } else formData.append('is_reservation', 'N');
          formData.append('user_name', watch().patientName);
          formData.append('phone', watch().patientTel);
          formData.append('height', watch().patientHeight);
          formData.append('weight', watch().patientWeight);
          formData.append('is_pregnant', watch().pregnancy ? 'Y' : 'N');
          formData.append('daily_coffee_intake', String(watch().dailyCoffee));
          formData.append(
            'caffeine_sensitivity',
            String(watch().caffeineSensitivity),
          );
          watch().dietPillsExperience.map((val: boolean, idx: number) => {
            if (val) formData.append('diet_pills_experience', String(idx + 1));
          });
          if (!watch().dietPillsExperience[0] && !isSupplyment) {
            formData.append('diet_pills_level', String(watch().dietPillsLevel));
            formData.append(
              'diet_pills_side_effects',
              String(watch().sideEffects || ''),
            );
          }
          formData.append('is_disease', watch().disease ? 'Y' : 'N');
          formData.append(
            'disease',
            watch().disease ? watch().diseaseDetail || '' : '',
          );
          formData.append('is_taking_medicines', watch().medicine ? 'Y' : 'N');
          formData.append(
            'taking_medicines',
            watch().medicine ? watch().medicineDetail || '' : '',
          );
          formData.append(
            'social_number',
            watch().patientSecurityNumber1 +
              '-' +
              watch().patientSecurityNumber2,
          );
          formData.append('question', watch().reason);
          if (isGlobal) {
            formData.append('is_oversea', isGlobal ? 'Y' : 'N');
            formData.append(
              'sns_type',
              watch().snsIdMethod === 'kakaotalk' ? 'kakao' : 'line',
            );
            formData.append('sns_id', watch().snsId);
          }
          await axiosClient.post('/func/prescription', formData).then((res) => {
            navigate(`/order-addr/${isDirect}/${cartId}`, {
              state: {
                isReservation: isInitial ? 'Y' : 'N',
                reservationAt: isInitial ? `${date} ${time}` : null,
                patientName: watch().patientName,
                patientTel: watch().patientTel,
                patientSecurityNumber1: watch().patientSecurityNumber1,
                patientSecurityNumber2: isInitial
                  ? '0000000'
                  : watch().patientSecurityNumber2,
                patientHeight: watch().patientHeight,
                patientWeight: watch().patientWeight,
                reason: watch().reason,
                pregnancy: watch().pregnancy,
                dailyCoffee: watch().dailyCoffee,
                caffeineSensitivity: watch().caffeineSensitivity,
                dietPillsExperience: watch().dietPillsExperience,
                dietPillsLevel: watch().dietPillsLevel,
                sideEffects: watch().sideEffects || '',
                disease: watch().disease,
                medicine: watch().medicine,
                diseaseDetail: watch().diseaseDetail || '',
                medicineDetail: watch().medicineDetail || '',
                snsIdMethod: watch().snsIdMethod || '',
                deliverySnsId: isGlobal ? watch().snsId : '',
              },
            });
          });
        });
    } else {
      if (isInitial) {
        date = `${appointmentDate!.getFullYear()}-${(
          '0' +
          (appointmentDate!.getMonth() + 1)
        ).slice(-2)}-${appointmentDate!.getDate()}`;
        time =
          (appointmentTime.substring(0, 2) == 'pm' &&
          appointmentTime.substring(2, 4) != '12'
            ? Number(appointmentTime.substring(2, 4)) +
              12 +
              ':' +
              appointmentTime.substring(5, 7)
            : appointmentTime.slice(-5)) + ':00';
        formData.append('is_reservation', 'Y');
        formData.append('reservation_at', `${date} ${time}`);
      } else formData.append('is_reservation', 'N');
      formData.append('user_name', watch().patientName);
      formData.append('phone', watch().patientTel);
      formData.append('height', watch().patientHeight);
      formData.append('weight', watch().patientWeight);
      formData.append('is_pregnant', watch().pregnancy ? 'Y' : 'N');
      formData.append('daily_coffee_intake', String(watch().dailyCoffee));
      formData.append(
        'caffeine_sensitivity',
        String(watch().caffeineSensitivity),
      );
      watch().dietPillsExperience.map((val: boolean, idx: number) => {
        if (val) formData.append('diet_pills_experience', String(idx + 1));
      });
      if (!watch().dietPillsExperience[0] && !isSupplyment) {
        formData.append('diet_pills_level', String(watch().dietPillsLevel));
        formData.append(
          'diet_pills_side_effects',
          String(watch().sideEffects || ''),
        );
      }
      formData.append('is_disease', watch().disease ? 'Y' : 'N');
      formData.append(
        'disease',
        watch().disease ? watch().diseaseDetail || '' : '',
      );
      formData.append('is_taking_medicines', watch().medicine ? 'Y' : 'N');
      formData.append(
        'taking_medicines',
        watch().medicine ? watch().medicineDetail || '' : '',
      );
      formData.append(
        'social_number',
        watch().patientSecurityNumber1 + '-' + watch().patientSecurityNumber2,
      );
      formData.append('question', watch().reason);
      if (isGlobal) {
        formData.append('is_oversea', isGlobal ? 'Y' : 'N');
        formData.append(
          'sns_type',
          watch().snsIdMethod === 'kakaotalk' ? 'kakao' : 'line',
        );
        formData.append('sns_id', watch().snsId);
      }
      await axiosClient.post('/func/prescription', formData).then((res) => {
        navigate(`/order-addr/${isDirect}/${productId}`, {
          state: {
            isReservation: isInitial ? 'Y' : 'N',
            reservationAt: isInitial ? `${date} ${time}` : null,
            patientName: watch().patientName,
            patientTel: watch().patientTel,
            patientSecurityNumber1: watch().patientSecurityNumber1,
            patientSecurityNumber2: isInitial
              ? '0000000'
              : watch().patientSecurityNumber2,
            patientHeight: watch().patientHeight,
            patientWeight: watch().patientWeight,
            reason: watch().reason,
            pregnancy: watch().pregnancy,
            dailyCoffee: watch().dailyCoffee,
            caffeineSensitivity: watch().caffeineSensitivity,
            dietPillsExperience: watch().dietPillsExperience,
            dietPillsLevel: watch().dietPillsLevel,
            sideEffects: watch().sideEffects || '',
            disease: watch().disease,
            medicine: watch().medicine,
            diseaseDetail: watch().diseaseDetail || '',
            medicineDetail: watch().medicineDetail || '',
            snsIdMethod: watch().snsIdMethod || '',
            deliverySnsId: isGlobal ? watch().snsId : '',
          },
        });
      });
    }
    setIsSubmitting(false);
  };
  const onSubmitError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    if (errorList[0].message.includes('예약 희망 날짜')) setCalendarOpen(true);
    window.scrollTo(
      0,
      window.scrollY + errorList[0].ref.getBoundingClientRect().top - 150,
    );
    alert(errorList[0].message);
  };

  useEffect(() => {
    axiosClient.get('/data/prescription').then((res) => {
      let result: ISavedPatientType = res.data.data;
      if (Object.keys(result).length === 0) {
        const token = localStorage.getItem('token');

        if (token) {
          if (!token || token == null || token == undefined || token == 'null')
            return;
          const userInfo: ITokenInfoType = jwt_decode(token!);
          setValue('patientTel', userInfo.phone.replace(/[-)+]/g, ''));
        }
        return;
      }
      if (nameRegex.test(result.user_name))
        setValue('patientName', result.user_name);
      setValue('patientTel', result.phone.trim().replace(/-/g, ''));
      setValue('patientHeight', String(result.height));
      setValue('patientWeight', String(result.weight));
      setValue('pregnancy', result.is_pregnant == 'Y' ? true : false);
      setValue('dailyCoffee', result.daily_coffee_intake);
      setValue('caffeineSensitivity', result.caffeine_sensitivity);
      setValue(
        'dietPillsExperience',
        (() => {
          let newVal = [false, false, false, false];
          result.diet_pills_experience.split(',').map((num: string) => {
            newVal[Number(num) - 1] = true;
          });
          return newVal;
        })(),
      );
      setValue('dietPillsLevel', result.diet_pills_level);
      setValue('sideEffects', result.diet_pills_side_effects || '');
      setValue('disease', result.is_disease == 'Y' ? true : false);
      setValue('diseaseDetail', result.disease || '');
      setValue('medicine', result.is_taking_medicines == 'Y' ? true : false);
      setValue('medicineDetail', result.taking_medicines || '');
      setIsGlobal(result.is_oversea === 'N' ? false : true);
      if (result.is_oversea === 'Y') {
        setValue(
          'snsIdMethod',
          result.sns_type === 'kakao' ? 'kakaotalk' : 'line',
        );
        setValue('snsId', result.sns_id);
      }

      const quesInfo = JSON.parse(
        sessionStorage.getItem('quesInfo') || JSON.stringify(''),
      );

      if (quesInfo) {
        if (quesInfo?.selectedAllItems)
          setSelectedAllItems(quesInfo.selectedAllItems);
        setValue('patientSecurityNumber1', quesInfo.patientSecurityNumber1);
        setValue('patientSecurityNumber2', quesInfo.patientSecurityNumber2);
        setValue('reason', quesInfo.reason);
        setValue('cautionCheck', quesInfo.cautionCheck);
        setIsGlobal(quesInfo.isGlobal);
        setValue('snsIdMethod', quesInfo.snsIdMethod);
        setValue('snsId', quesInfo.deliverySnsId);
        setValue('privacyCheck', quesInfo.privacyCheck);
        // sessionStorage.removeItem('quesInfo');
      }
    });
  }, []);
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);
  useEffect(() => {
    setAppointmentTime('');
  }, [appointmentDate]);
  useEffect(() => {
    if (!appointmentDate || !appointmentTime) {
      setValue('appointmentDate', '');
      return;
    }
    setCalendarOpen(false);
    setValue(
      'appointmentDate',
      `${appointmentDate.getFullYear()}.${
        appointmentDate.getMonth() + 1
      }.${appointmentDate.getDate()}(${WEEKDAY[appointmentDate.getDay()]}) / ${
        appointmentTime.substring(0, 2) == 'am' ? '오전' : '오후'
      } ${appointmentTime.substring(2)}`,
    );
  }, [appointmentTime]);

  // 보조제만 선택했는지 확인
  const [isSupplyment, setIsSupplyment] = useState(false);

  useEffect(() => {
    // 보조제만 선택한 경우
    if (
      watch().dietPillsExperience[3] === true &&
      watch()
        .dietPillsExperience.slice(0, 3)
        .every((item: boolean) => item === false)
    ) {
      setIsSupplyment(true);
      setValue('dietPillsLevel', 0);
      setValue('sideEffects', '');
    } else setIsSupplyment(false);
  }, [watch().dietPillsExperience, watch().sideEffects]);

  function resetAppointment() {
    setAppointmentDate(null);
    setAppointmentTime('');
    setValue('appointmentDate', '');
  }
  function copyAddress() {
    navigator.clipboard
      .writeText('서울 강남구 테헤란로64길 8 지하1층')
      .then((res) => alert('주소가 복사되었습니다.'));
  }
  return (
    <div className={`${styles.container} ${!isDesktop ? styles.isMobile : ''}`}>
      {isDesktop && <Header />}
      <div className={styles.contentArea}>
        {isInitial && (
          <section className={styles.appointmentWrap}>
            <h3>병원 방문 예약하기</h3>
            <article className={styles.hospitalInfoWrap}>
              <div className={styles.hospitalDetail}>
                <h1>마디로 한의원 선릉점</h1>
                <div
                  onClick={() => {
                    window.open(
                      'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                      '_blank',
                    );
                  }}
                >
                  <span>지도보기</span>
                  <img src={rightArrow} alt="화살표" />
                </div>
              </div>
              <div className={styles.hospitalAddress}>
                <h6>병원주소</h6>
                <div className={styles.addressDetail}>
                  <span>서울 강남구 테헤란로64길 8 지하1층</span>
                  <div className={styles.copyBtn} onClick={copyAddress}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                        stroke="#FF5656"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.04163 5.8335V12.8335H8.16663"
                        stroke="#FF5656"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                        stroke="#FF5656"
                        strokeLinecap="square"
                      />
                    </svg>
                    <span>복사</span>
                  </div>
                </div>
              </div>
            </article>
            <article
              className={styles.appointmentDateWrap}
              {...register('appointmentDate', {
                value: '',
                required: {
                  value: !watch().appointmentDate,
                  message: '예약 희망 날짜를 선택해주세요.',
                },
              })}
            >
              <div className={styles.label}>
                <div className={styles.left}>
                  <span>*</span>
                  <span>예약 희망 날짜</span>
                </div>
                <div className={styles.right}>
                  <div className={styles.dropboxWrap}>
                    <div
                      className={styles.dropbox}
                      onClick={() => setCalendarOpen((prev) => !prev)}
                    >
                      <span>옵션을 선택해주세요.</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        style={{
                          transform: calendarOpen ? 'rotate(180deg)' : 'unset',
                        }}
                      >
                        <path
                          d="M3.5 7.5L11 14.5L18.5 7.5"
                          stroke="#D1D0D5"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                    {calendarOpen && (
                      <AppointmentsCalendar
                        appointmentDate={appointmentDate}
                        setAppointmentDate={setAppointmentDate}
                        appointmentTime={appointmentTime}
                        setAppointmentTime={setAppointmentTime}
                      />
                    )}
                  </div>
                  {watch().appointmentDate && (
                    <div className={styles.appointmentInfo}>
                      <span>{watch().appointmentDate}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={resetAppointment}
                      >
                        <path
                          d="M15.8332 4.16675L4.1665 15.8334M4.16652 4.16675L15.8332 15.8334"
                          stroke="#131C31"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                  {/* <p>
                                        *병원 스케줄에 따라 예약 날짜가 변경될
                                        수 있습니다.
                                        <br />
                                        접수 완료 후, 방문 시간 안내 전화를
                                        드립니다.
                                    </p> */}
                </div>
              </div>
            </article>
          </section>
        )}
        {!isDesktop && (
          <div className={styles.prevWrap}>
            <img onClick={() => navigate(-1)} src={mPrev} alt="mPrev" />
          </div>
        )}
        <div className={styles.left}>
          <div className={styles.stepTitleWrap}>
            <span>결제하기</span>
            <div className={styles.stepWrap}>
              <span>문진표작성</span>
              <div className={styles.hitStep}>1</div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className={styles.orderTitleWrap}>
            <img src={pencil} alt="pencil" />
            <span>문진표 작성</span>
          </div>
          {prescriptionProgramList.includes(detail?.product_id) && (
            <div className={styles.productListWrap}>
              {prescriptionProgramList.includes(detail?.product_id) && (
                <div className={styles.productInfoWrap}>
                  <div className={styles.thumbnailWrap}>
                    <img src={detail && detail.list_image} alt="thumbnail" />
                  </div>
                  <div className={styles.productInfo}>
                    <h3 className={styles.productName}>
                      {detail && detail.title}
                    </h3>
                    <div className={styles.optionWrap}>
                      <div className={styles.option}>
                        <span>필수 옵션</span>
                        <div
                          className={styles.optionBox}
                          onClick={() => setFirstOptionOpen((prev) => !prev)}
                        >
                          <span
                            style={{
                              color: selectedFirstOption
                                ? '#131C31'
                                : '#abb1bb',
                            }}
                          >
                            {selectedFirstOption
                              ? selectedFirstOption.po_poptionname
                              : '옵션을 선택해주세요.'}
                          </span>
                          <img
                            src={bottomArrow}
                            alt="bottomArrow"
                            style={{
                              transform: firstOptionOpen
                                ? 'rotate(180deg)'
                                : 'none',
                            }}
                          />
                          {firstOptionOpen && (
                            <ul className={styles.optionList}>
                              {detail && detail.option_depth > 0 ? (
                                firstOptionList?.map((option) => (
                                  <li
                                    key={option.po_uid}
                                    onClick={() =>
                                      setSelectedFirstOption(option)
                                    }
                                  >
                                    {option.po_poptionname}
                                  </li>
                                ))
                              ) : (
                                <li
                                  onClick={() =>
                                    setSelectedFirstOption({
                                      po_poptionname: detail && detail.title,
                                      po_poptionprice:
                                        detail && detail.discount_price,
                                      po_uid: 0,
                                    })
                                  }
                                >
                                  {detail && detail.title}
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                      {detail && detail.option_depth > 1 && (
                        <div className={styles.option}>
                          <span>필수 옵션</span>
                          <div
                            className={styles.optionBox}
                            onClick={() => {
                              if (!selectedFirstOption) {
                                alert('첫 번째 옵션을 선택해주세요.');
                                setFirstOptionOpen(true);
                                return;
                              }
                              setSecondOptionOpen((prev) => !prev);
                            }}
                          >
                            <span>옵션을 선택해주세요.</span>
                            <img
                              src={bottomArrow}
                              alt="bottomArrow"
                              style={{
                                transform: secondOptionOpen
                                  ? 'rotate(180deg)'
                                  : 'none',
                              }}
                            />
                            {secondOptionOpen && (
                              <ul className={styles.optionList}>
                                {secondOptionList?.map((option) => (
                                  <li
                                    key={option.po_uid}
                                    onClick={() =>
                                      setSelectedSecondOption(option)
                                    }
                                  >
                                    {option.po_poptionname}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {selectedAllItems.length > 0 && (
                <ul className={styles.selectedOptionListWrap}>
                  {selectedAllItems.map((option, idx) => (
                    <li key={idx}>
                      <div className={!isDesktop ? styles.isMobile : ''}>
                        <span className={styles.optionName}>
                          {`${option.first?.po_poptionname}`}
                          {option.second &&
                            ` / ${option.second?.po_poptionname}`}
                          {option.third && ` / ${option.third?.po_poptionname}`}
                        </span>
                        <div className={styles.counter}>
                          <div
                            className={styles.minus}
                            onClick={() => handleCountChange(idx, false)}
                          >
                            <img src={minus} alt="minus" />
                          </div>
                          <div className={styles.count}>{option.count}</div>
                          <div
                            className={styles.plus}
                            onClick={() => handleCountChange(idx, true)}
                          >
                            <img src={plus} alt="plus" />
                          </div>
                        </div>
                        <div className={styles.editBox}>
                          <div className={styles.optionPrice}>
                            <span>
                              {option.third &&
                              option.third.po_poption_supplyprice
                                ? option.third.po_poption_supplyprice?.toLocaleString() ||
                                  ''
                                : option.second &&
                                  option.second.po_poption_supplyprice
                                ? option.second.po_poption_supplyprice?.toLocaleString() ||
                                  ''
                                : (option.first.po_poption_supplyprice &&
                                    option.first.po_poption_supplyprice!.toLocaleString()) ||
                                  ''}
                            </span>
                            <span>
                              {option.third
                                ? option.third.po_poptionprice?.toLocaleString()
                                : option.second
                                ? option.second.po_poptionprice?.toLocaleString()
                                : option.first.po_poptionprice!.toLocaleString()}
                            </span>
                          </div>
                          <img
                            src={closeBtn}
                            alt="closeBtn"
                            onClick={() => handleSelectedItemDelete(idx)}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {prescriptionProgramList.includes(detail?.product_id) && (
                <div className={styles.totalPriceWrap}>
                  <span className={styles.head}>총 금액</span>
                  <span className={styles.totalPrice}>
                    <span>{allItemsPrice.toLocaleString()}원</span>
                    <span>{allItemsCount !== 0 && `(${allItemsCount}개)`}</span>
                  </span>
                </div>
              )}
              {!prescriptionProgramList.includes(detail?.product_id) &&
                orderData?.carts.map((company) =>
                  company.cart.map((product) => (
                    <div
                      className={styles.productInfoWrap}
                      key={product.product.product_id}
                    >
                      <div className={styles.thumbnailWrap}>
                        <img src={product.product.list_image} alt="thumbnail" />
                      </div>
                      <div className={styles.productInfo}>
                        <h3 className={styles.productName}>
                          {product.product.title}
                        </h3>
                        <ul className={styles.optionList}>
                          {product.product.option_depth > 0 &&
                            product.items.map((option) => (
                              <li key={option.id}>
                                <span>
                                  {option.option1 && option.option1}
                                  {option.option2 && ' / ' + option.option2}
                                  {option.option3 && ' / ' + option.option3}
                                </span>
                                <span>수량 {option.count}개</span>
                              </li>
                            ))}
                        </ul>
                        <div className={styles.totalInfoWrap}>
                          {(() => {
                            let res = productOptionCal(product);
                            return (
                              <>
                                <span>{res.price.toLocaleString()}원</span>
                                <span>수량 {res.count}개</span>
                              </>
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  )),
                )}
            </div>
          )}
          <div className={styles.questionnaireWrap}>
            <form
              className={styles.questionnaireForm}
              onSubmit={(e) => e.preventDefault()}
            >
              <label className={`${styles.label} ${styles.nameLabel}`}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>이름</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.patientName ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="이름"
                    className={`${styles.normalInput} name`}
                    {...register('patientName', {
                      value: '',
                      required: {
                        value: true,
                        message: '이름은 필수 입력사항입니다.',
                      },
                      pattern: {
                        value: nameRegex,
                        message: '올바른 이름 형식이 아닙니다.',
                      },
                    })}
                  />
                </div>
                <p>한글, 영문, 공백만 사용 가능합니다.</p>
              </label>
              <label className={styles.phoneNumberLabel}>
                <div className={`${styles.labelWrap} ${styles.phoneLabelWrap}`}>
                  <div>
                    <span>*</span>
                    <span>연락처</span>
                  </div>
                  <div
                    className={`${styles.globalCheckWrap} ${
                      isGlobal ? styles.hit : ''
                    }`}
                    onClick={() => {
                      setIsGlobal((prev) => !prev);
                    }}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>지금 해외에 있어서 전화를 받기가 어려워요.</span>
                  </div>
                </div>
                <div className={styles.box}>
                  {isGlobal && (
                    <div className={styles.socialWrap}>
                      <div className={styles.socialMethodWrap}>
                        <div
                          className={styles.customCheckBoxListWrap}
                          {...register('snsIdMethod', {
                            value: 'kakaotalk',
                            validate: () =>
                              !watch().snsIdMethod
                                ? '카카오톡 또는 라인아이디 방법을 선택해주세요.'
                                : true,
                          })}
                        >
                          <div
                            className={`${styles.customCheckBoxWrap} ${
                              watch().snsIdMethod === 'kakaotalk'
                                ? styles.hit
                                : ''
                            } ${styles.kakaoCustomWrap}`}
                            onClick={() => {
                              if (watch().snsIdMethod !== 'kakaotalk')
                                setValue('snsId', '');
                              setValue('snsIdMethod', 'kakaotalk');
                            }}
                          >
                            <div className={styles.customCheckBox}></div>
                            <span>KakaoTalk ID</span>
                          </div>
                          <div
                            className={`${styles.customCheckBoxWrap} ${
                              watch().snsIdMethod === 'line' ? styles.hit : ''
                            }`}
                            onClick={() => {
                              if (watch().snsIdMethod !== 'line')
                                setValue('snsId', '');
                              setValue('snsIdMethod', 'line');
                            }}
                          >
                            <div className={styles.customCheckBox}></div>
                            <span>Line ID</span>
                          </div>
                        </div>
                      </div>
                      <label className={styles.labelWrap}>
                        <div
                          className={`${styles.socialInputWrap} ${
                            errors.snsId ? styles.validationError : ''
                          }`}
                        >
                          <input
                            type="text"
                            placeholder="카카오톡 또는 라인아이디"
                            className={styles.normalInput}
                            {...register('snsId', {
                              value: '',
                              required: {
                                value: true,
                                message:
                                  '카카오톡 또는 라인 아이디를 입력해주세요.',
                              },
                            })}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                  <div
                    className={`${styles.phoneNumberInuptWrap} ${
                      errors.patientTel ? styles.validationError : ''
                    }`}
                  >
                    <input
                      type="text"
                      placeholder=""
                      className={`${styles.normalInput} tel`}
                      {...register('patientTel', {
                        value: '',
                        required: {
                          value: true,
                          message: '연락처는 필수 입력사항입니다.',
                        },
                        minLength: {
                          value: 8,
                          message: '연락처를 올바르게 입력해주세요.',
                        },
                        pattern: !isGlobal
                          ? {
                              value: phoneRegex,
                              message: '올바른 연락처 형식이 아닙니다.',
                            }
                          : undefined,
                        onChange: (e) => {
                          let value = e.currentTarget.value;
                          if (!/^[0-9]+$/g.test(value)) {
                            setValue(
                              'patientTel',
                              value.slice(0, value.length - 1),
                            );
                          }
                        },
                      })}
                    />
                  </div>
                  <div className={styles.telAlertWrap}>
                    <p>
                      의료진이 진료를 위해 전화를 드리므로 이름과 연락처를
                      {isMobile && <br />} 정확하게 기재해주셔야 빠른 진료를
                      받으실수 있습니다.
                    </p>
                    {isGlobal && (
                      <p className={styles.globalTelAlert}>
                        * 해외 체류로 인해 카카오톡/라인 ID를 입력하신 경우,
                        보이스톡으로 진료를 진행합니다. 반드시{' '}
                        <span className={styles.hit}>아이디 검색 허용</span>을
                        미리 설정해 주세요.
                      </p>
                    )}
                  </div>
                </div>
              </label>
              <label className={styles.securityNumberLabel}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>주민등록번호 {isInitial && '앞자리'}</span>
                </div>
                <div>
                  <div
                    className={`${styles.securityNumberInuptWrap} ${
                      errors.patientSecurityNumber1 ||
                      errors.patientSecurityNumber2
                        ? styles.validationError
                        : ''
                    }`}
                  >
                    <input
                      type="number"
                      {...register('patientSecurityNumber1', {
                        value: '',
                        required: {
                          value: true,
                          message: '주민등록번호를 입력해주세요.',
                        },
                        maxLength: {
                          value: 6,
                          message:
                            '주민등록번호 앞자리를 6글자로 작성해주세요.',
                        },
                        minLength: {
                          value: 6,
                          message:
                            '주민등록번호 앞자리를 6글자로 작성해주세요.',
                        },
                        pattern: {
                          value: /^[0-9]{6}$/,
                          message: '주민등록번호는 숫자만 입력 가능합니다.',
                        },
                        onChange: (e) => {
                          if (e.currentTarget.value.length > 6)
                            setValue(
                              'patientSecurityNumber1',
                              e.currentTarget.value.slice(0, 6),
                            );
                        },
                      })}
                      max={9999}
                      maxLength={6}
                      className={isInitial ? styles.fullWidth : ''}
                      placeholder="000000"
                    />
                    {!isInitial && (
                      <>
                        <span>-</span>
                        <input
                          type="password"
                          autoComplete="off"
                          {...register('patientSecurityNumber2', {
                            value: '',
                            required: {
                              value: true,
                              message: '주민등록번호를 입력해주세요.',
                            },
                            minLength: {
                              value: 7,
                              message:
                                '주민등록번호 뒷자리를 7글자로 작성해주세요.',
                            },
                            onChange: (e) => {
                              if (isNaN(Number(e.currentTarget.value)))
                                setValue(
                                  'patientSecurityNumber2',
                                  e.currentTarget.value.slice(
                                    0,
                                    e.currentTarget.value.length - 1,
                                  ),
                                );
                              if (e.currentTarget.value.length > 7)
                                setValue(
                                  'patientSecurityNumber2',
                                  e.currentTarget.value.slice(0, 7),
                                );
                            },
                          })}
                          placeholder="0000000"
                        />
                      </>
                    )}
                  </div>
                  <div className={styles.priNumAlertWrap}>
                    <p>
                      비대면 진료를 받기 위해서는 의료법에 의해 의료기관이
                      주민번호를 확인합니다. 커넥트 닥터는 해당 정보를 확인할 수
                      없습니다.
                    </p>
                    <p className={`${isGlobal && styles.globalPriNumAlert} `}>
                      *해외거주자 분들은 000000-0000000 으로 입력해주세요.
                    </p>
                  </div>
                </div>
              </label>
              <label className={styles.label}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>키</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.patientHeight ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    className={`${styles.normalInput} height`}
                    {...register('patientHeight', {
                      value: '',
                      required: {
                        value: true,
                        message: '키를 입력해주세요.',
                      },
                      onChange: (e) => {
                        let value = e.currentTarget.value;
                        if (isNaN(Number(e.currentTarget.value)))
                          setValue(
                            'patientHeight',
                            value.slice(0, value.length - 1),
                          );
                      },
                    })}
                  />
                  <span className={styles.inputUnit}>cm</span>
                </div>
              </label>
              <label className={styles.label}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>체중</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.patientWeight ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    className={`${styles.normalInput} weight`}
                    {...register('patientWeight', {
                      value: '',
                      required: {
                        value: true,
                        message: '체중을 입력해주세요.',
                      },
                      onChange: (e) => {
                        let value = e.currentTarget.value;
                        if (isNaN(Number(e.currentTarget.value)))
                          setValue(
                            'patientWeight',
                            value.slice(0, value.length - 1),
                          );
                      },
                    })}
                    // value={patientWeight}
                    // onChange={(e) => {
                    //   let value = e.currentTarget.value;
                    //   if (isNaN(Number(e.currentTarget.value))) return;
                    //   setPatientWeight(value);
                    // }}
                    // onBlur={() => setPatientWeight((prev) => prev.trim())}
                  />
                  <span className={styles.inputUnit}>kg</span>
                </div>
              </label>
              <label className={`${styles.label} ${styles.reasonLabel}`}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>상담사유</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.reason ? styles.validationError : ''
                  }`}
                >
                  <textarea
                    className={`${styles.reason} reason`}
                    {...register('reason', {
                      value: '',
                      required: {
                        value: true,
                        message: '상담사유를 입력해주세요.',
                      },
                    })}
                    placeholder="다이어트"
                  ></textarea>
                </div>
              </label>
              <div
                className={`${styles.pregnancyLabel} ${
                  errors.pregnancy ? styles.warning : ''
                }`}
                {...register('pregnancy', {
                  required: {
                    value: watch().pregnancy == undefined,
                    message: '임신 여부를 선택해주세요.',
                  },
                })}
              >
                <div>
                  <span>*</span>
                  <span>임신 여부</span>
                </div>
                <div className={styles.checkOutWrap}>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      watch().pregnancy ? styles.hit : ''
                    } pregnancy`}
                    onClick={() => setValue('pregnancy', true)}

                    // onClick={() => setPregnancy(true)}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>예</span>
                  </div>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      watch().pregnancy === false ? styles.hit : ''
                    }`}
                    onClick={() => setValue('pregnancy', false)}

                    // onClick={() => setPregnancy(false)}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>아니오</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={`${styles.prescriptionDetailWrap}`}>
            <label
              className={`${styles.label} ${
                errors.dailyCoffee ? styles.warning : ''
              } dailyCoffee`}
              {...register('dailyCoffee', {
                value: 0,
                validate: () =>
                  !/^[1-9]$/g.test(watch().dailyCoffee)
                    ? '하루 섭취 커피량을 선택해주세요.'
                    : true,
              })}
            >
              <div className={styles.labelWrap}>
                <span>*</span>
                <span>하루 커피량</span>
              </div>
              <ul
                className={`${styles.checkboxArea} ${
                  isMobile ? styles.isMobile : ''
                }`}
              >
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 1 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 1)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>0잔</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 2 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 2)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>1잔</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 3 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 3)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>2잔</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 4 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 4)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>3잔 이상</span>
                </li>
              </ul>
            </label>
            <label
              className={`${styles.label} ${styles.caffeineSensitivity} ${
                errors.caffeineSensitivity ? styles.warning : ''
              } caffeineSensitivity`}
              {...register('caffeineSensitivity', {
                value: 0,
                validate: () =>
                  !/^[1-9]$/g.test(watch().caffeineSensitivity)
                    ? '카페인 예민도를 선택해주세요.'
                    : true,
              })}
            >
              <div className={styles.labelWrap}>
                <span>*</span>
                <span>카페인 예민도</span>
              </div>
              <ul
                className={`${styles.checkboxArea} ${
                  isMobile ? styles.isMobile : ''
                }`}
              >
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().caffeineSensitivity == 1 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('caffeineSensitivity', 1)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>없음</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().caffeineSensitivity == 2 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('caffeineSensitivity', 2)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>약간</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().caffeineSensitivity == 3 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('caffeineSensitivity', 3)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>예민</span>
                </li>
              </ul>
            </label>
            <label
              className={`${styles.label} ${
                errors.dietPillsExperience ? styles.warning : ''
              } dietPillsExperience`}
              {...register('dietPillsExperience', {
                value: [false, false, false, false],
                validate: () =>
                  watch().dietPillsExperience &&
                  !watch().dietPillsExperience.includes(true)
                    ? '다이어트 약 복용 경험을 선택해주세요.'
                    : true,
              })}
            >
              <div className={styles.labelWrap}>
                <span>*</span>
                <span>다이어트 약 복용 경험</span>
              </div>
              <ul
                className={`${styles.checkboxArea} ${
                  isMobile ? styles.isMobile : ''
                }`}
              >
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[0]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      true,
                      false,
                      false,
                      false,
                    ]);
                    setValue('dietPillsLevel', 0);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>없음</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[1]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      false,
                      !watch().dietPillsExperience[1],
                      watch().dietPillsExperience[2],
                      watch().dietPillsExperience[3],
                    ]);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>한약</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[2]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      false,
                      watch().dietPillsExperience[1],
                      !watch().dietPillsExperience[2],
                      watch().dietPillsExperience[3],
                    ]);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>양약</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[3]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      false,
                      watch().dietPillsExperience[1],
                      watch().dietPillsExperience[2],
                      !watch().dietPillsExperience[3],
                    ]);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>보조제</span>
                </li>
              </ul>
            </label>
            {watch().dietPillsExperience &&
              !watch().dietPillsExperience[0] &&
              !isSupplyment &&
              watch().dietPillsExperience.includes(true) && (
                <>
                  <label
                    className={`${styles.label} ${
                      errors.dietPillsLevel ? styles.warning : ''
                    } dietPillsLevel`}
                    {...register('dietPillsLevel', {
                      value: 0,
                      validate: () =>
                        !/^[1-9]$/g.test(watch().dietPillsLevel)
                          ? '복용 약 단계 및 강도를 선택해주세요.'
                          : true,
                      // required: {
                      //   value:
                      //     watch().dietPillsExperience &&
                      //     !watch().dietPillsExperience[0] &&
                      //     true,
                      //   message: '복용 약 단계 및 강도를 선택해주세요.',
                      // },
                    })}
                  >
                    <div className={styles.labelWrap}>
                      <span>*</span>
                      <span>복용 약 단계 및 강도</span>
                    </div>
                    <ul
                      className={`${styles.checkboxArea} ${
                        isMobile ? styles.isMobile : ''
                      }`}
                    >
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 1 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 1)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>모름</span>
                      </li>
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 2 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 2)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>약함</span>
                      </li>
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 3 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 3)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>보통</span>
                      </li>
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 4 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 4)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>강함</span>
                      </li>
                    </ul>
                  </label>
                  <label
                    className={`${styles.label} ${
                      errors.sideEffects ? styles.warning : ''
                    }`}
                  >
                    <div className={styles.labelWrap}>
                      <span>*</span>
                      <span>복용 시 불편사항</span>
                    </div>
                    <div
                      className={`${styles.inputWrap} ${
                        errors.sideEffects ? styles.validationError : ''
                      }`}
                    >
                      <textarea
                        className={`${styles.sideEffects} sideEffects`}
                        {...register('sideEffects', {
                          value: '',
                          required: {
                            value:
                              watch().dietPillsExperience &&
                              !watch().dietPillsExperience[0] &&
                              true,
                            message:
                              '다이어트 약 복용시 불편사항을 입력해주세요.',
                          },
                        })}
                      ></textarea>
                    </div>
                  </label>
                </>
              )}
          </div>
          <div
            className={`${styles.diseaseCheckWrap} ${
              errors.disease ? styles.warning : ''
            } disease`}
            {...register('disease', {
              required: {
                value: watch().disease == undefined,
                message: '질병 여부를 선택해주세요.',
              },
            })}
          >
            <div className={styles.checkArea}>
              <div>
                <span>*</span>
                <span>질병 여부</span>
              </div>
              <div className={styles.checkOutWrap}>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().disease ? styles.hit : ''
                  }`}
                  onClick={() => setValue('disease', true)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>예</span>
                </div>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().disease === false ? styles.hit : ''
                  }`}
                  onClick={() => setValue('disease', false)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>아니오</span>
                </div>
              </div>
            </div>
            {watch().disease && (
              <>
                <span className={styles.sectionGuide}>
                  앓고 있는 질환을 상세히 작성해주세요.
                </span>
                <textarea
                  placeholder="예) 불면증, 당뇨"
                  {...register('diseaseDetail', {
                    value: '',
                  })}
                />
              </>
            )}
          </div>
          <div
            className={`${styles.medicineCheckWrap} ${
              errors.medicine ? styles.warning : ''
            } medicine`}
            {...register('medicine', {
              required: {
                value: watch().medicine == undefined,
                message: '복용중인 약 여부를 선택해주세요.',
              },
            })}
          >
            <div className={styles.checkArea}>
              <div>
                <span>*</span>
                <span>복용중인 약</span>
              </div>
              <div className={styles.checkOutWrap}>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().medicine ? styles.hit : ''
                  }`}
                  onClick={() => setValue('medicine', true)}
                  // onClick={() => setMedicine(true)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>예</span>
                </div>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().medicine === false ? styles.hit : ''
                  }`}
                  onClick={() => setValue('medicine', false)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>아니오</span>
                </div>
              </div>
            </div>
            {watch().medicine && (
              <>
                <span className={styles.sectionGuide}>
                  복용중인 약의 종류를 작성해주세요
                </span>
                <textarea
                  placeholder="예) 수면제(벤조디아제핀)"
                  // value={medicineDetail}
                  // onChange={(e) => setMedicineDetail(e.currentTarget.value)}
                  {...register('medicineDetail', {
                    value: '',
                  })}
                />
              </>
            )}
          </div>
          <div
            className={`${styles.cautionWrap} ${
              errors.cautionCheck ? styles.isError : ''
            }`}
          >
            <div className={styles.labelWrap}>
              <span>*</span>
              <span>주의사항</span>
            </div>
            <ul>
              <li>
                <span>1.&nbsp;</span>
                <span>
                  빠른 감량 원하시는 분들은 식사 1시간 전 (점심, 저녁 전) 으로
                  하루 2번 복용이 원칙입니다.
                </span>
              </li>
              <li>
                <span>2.&nbsp;</span>
                <span>
                  수면이 불편하신 분들은 저녁복용을 오후 4시로 당겨서
                  복용합니다.
                </span>
              </li>
              <li>
                <span>3.&nbsp;</span>
                <span>
                  초기 적응기간 3~4일 동안 예민하게 느끼시는 분들은 복용량을
                  절반으로 줄여 드시다가 적응 후 정량 드시면 됩니다.
                </span>
              </li>
              <li>
                <span>4.&nbsp;</span>
                <span>
                  처방 한의약품은 환자 본인만 복용해야 하며, 타인이 복용해서는
                  안 됩니다.
                </span>
              </li>
            </ul>
            <div
              className={`${styles.confirm} ${
                watch().cautionCheck ? styles.hit : ''
              }`}
              {...register('cautionCheck', {
                value: false,
                required: {
                  value: watch().cautionCheck == false,
                  message: '주의사항을 확인 후 체크 버튼을 눌러주세요.',
                },
              })}
              onClick={() => setValue('cautionCheck', !watch().cautionCheck)}
            >
              <div className={styles.customCheckBox}></div>
              <span>복용에 관한 주의사항을 확인했습니다.</span>
            </div>
          </div>
          <div
            className={`${styles.cautionWrap} ${
              errors.privacyCheck ? styles.isError : ''
            }`}
          >
            <div className={styles.labelWrap}>
              <span>*</span>
              <span>개인정보 기입 확인 및 안내사항</span>
            </div>
            <ul>
              <li>
                <span>1.&nbsp;</span>
                <span>
                  개인정보(이름, 연락처 등)를 정확하게 입력했는지 한 번 더
                  확인해 주세요. 개인정보 오기입 시, 불이익(비대면 진료 지연
                  등)이 발생할 수 있습니다.
                </span>
              </li>
              <li>
                <span>2.&nbsp;</span>
                <span>
                  주문 완료 후 비대면 진료 시{' '}
                  <span className={styles.hit}>제품의 단계는 변경 가능</span>
                  하니, 부담 없이 주문해 주세요.
                </span>
              </li>
            </ul>
            <div
              className={`${styles.confirm} ${
                watch().privacyCheck ? styles.hit : ''
              }`}
              {...register('privacyCheck', {
                value: false,
                required: {
                  value: watch().privacyCheck == false,
                  message: '개인정보 기입 확인 후 체크 버튼을 눌러주세요.',
                },
              })}
              onClick={() => setValue('privacyCheck', !watch().privacyCheck)}
            >
              <div className={styles.customCheckBox}></div>
              <span>개인정보를 정확하게 입력했습니다.</span>
            </div>
          </div>
          <div
            className={`${styles.btnWrap} ${!isDesktop ? styles.isMobile : ''}`}
          >
            <button
              className={styles.submitBtn}
              onClick={handleSubmit(onSubmit, onSubmitError)}
            >
              다음
            </button>
          </div>
        </div>
        {/* <div className={styles.right}>결제페이지로 이동</div> */}
      </div>
    </div>
  );
}
export default Questionnaire;
