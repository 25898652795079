import axiosClient from "../libs/axiosClient";

async function checkRecentAuth() {
  let result = false;
  
  try {
    const response = await axiosClient.get("/data/verification/is_id_check");
    console.log(response);
    const isChecked = response.data.data.is_checked;
    const checkDate = response.data.data.check_date;
    

    if (isChecked === "Y") {
      console.log("본인인증 기록 있음.", checkDate);
      result = true;
      return true;
    } else {
      console.log("본인인증 기록 없음.");
      return false
    }
  } catch (error) {
    console.error("본인인증 확인 중 오류 발생: ", error);
  }
  
  return result;
}

export default checkRecentAuth;
